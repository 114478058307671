import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { SystemProductsFilters } from "../SystemProductsFilters";
import { AppDispatch, RootState } from "../../../app/store";
import { FC, useCallback, useEffect, useState } from "react";
import { actions, fetchTenantStock } from "../slice";
import { SystemProductsList } from "../SystemProductsList";
import { useSearchParams } from "react-router-dom";
import { SystemStationDetails } from "../SystemStationDetails";
import styles from "./SystemProducts.module.css";
import { SystemStationOrders } from "../SystemStationOrders";
import { exportToCSV } from "../api";
import { format } from "date-fns";

interface Props {
  tenantId: number;
  systemId: number;
}

export const SystemProducts: FC<Props> = ({ tenantId, systemId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { searchFilter, products, productsStatus, selectedStation, stations } =
    useSelector((state: RootState) => {
      return state.system;
    });

  const filterProducts = useCallback(() => {
    let _products = products;
    if (searchFilter) {
      _products = _products.filter((p) => {
        if (p.name.toLowerCase().includes(searchFilter.toLowerCase())) {
          return true;
        }
        if (p.sku.toLowerCase().includes(searchFilter.toLowerCase())) {
          return true;
        }
        return false;
      });
    }
    return _products;
  }, [searchFilter, products]);

  const [filteredProducts, setFilteredProducts] = useState(filterProducts());

  const getStock = useCallback(() => {
    dispatch(fetchTenantStock(tenantId));
  }, [tenantId, dispatch]);

  useEffect(() => {
    getStock();
  }, [getStock]);

  useEffect(() => {
    setFilteredProducts(filterProducts());
  }, [filterProducts]);

  useEffect(() => {
    const palletId = searchParams.get("palletId");
    const pallet = stations.find((s) => s.palletId === palletId);
    if (selectedStation?.palletId !== palletId) {
      dispatch(actions.setSelectedPallet(pallet || null));
    }
    dispatch(
      actions.setFilters({
        searchFilter: searchParams.get("searchString") || "",
      })
    );
  }, [searchParams, dispatch, selectedStation, stations]);

  const handleCloseDrawer = () => {
    searchParams.delete("palletId");
    setSearchParams(searchParams);
  };

  const handleExportProducts = useCallback(async () => {
    const csv = await exportToCSV(tenantId);
    const name = `tenant_stock_${format(new Date(), "dd-mm-yyyy_HH:MM")}`;
    const blob = new Blob([csv.data], { type: "text/csv;charset=utf-8" });
    saveAs(blob, name);
  }, [tenantId]);

  return (
    <div className={styles.container}>
      <SystemProductsFilters searchString={searchFilter} />
      <SystemProductsList
        status={productsStatus}
        products={filteredProducts}
        onExport={handleExportProducts}
      />
      <SystemStationDetails systemId={systemId} onHide={handleCloseDrawer} />
      <SystemStationOrders
        systemId={systemId}
        onHide={handleCloseDrawer}
        tenantId={tenantId}
      />
    </div>
  );
};
