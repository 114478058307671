import { FC } from "react";
import { ImagePlaceholder } from "../../assets";
import styles from "./ProductPhotoCell.module.css";

interface Props {
  name: string;
  imageId?: string;
}

export const ProductPhotoCell: FC<Props> = ({ name, imageId }) => {
  console.log(name);
  return (
    <div className={styles.wrapper}>
      <span className={styles.container}>
        {imageId ? (
          <img
            src={`https://imagedelivery.net/aVfW2-rBW-pPqTdLRDNMyA/${imageId}/public`}
            alt={name}
          />
        ) : (
          <ImagePlaceholder />
        )}
      </span>
      {name}
    </div>
  );
};
