import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ColumnType } from "antd/lib/table";
import { actions } from "../slice";
import { AppDispatch } from "../../../app/store";
import { ApiStatuses, SortTypes } from "../../../app/types";
import { LMTable } from "../../../components/LMTable";
import { IStoreProduct } from "../types";
import { LMInputNumberIncremental } from "../../../components/LMInputNumberIncremental";
import { ProductPhotoCell } from "../../../components";

interface Props {
  list: IStoreProduct[];
  cartProducts: IStoreProduct[];
  status: ApiStatuses;
  searchString: string;
}

export const ProductsStoreList: FC<Props> = ({
  list,
  status,
  cartProducts,
  searchString,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("store");

  const handleSortNameChange = (column: string, order: SortTypes) => {
    if (column === "name") {
      dispatch(actions.setNameSort(order));
    }
  };

  const filterProducts = useCallback(() => {
    let products = list;
    if (searchString) {
      products = products.filter((p) => {
        if (p.name.toLowerCase().includes(searchString.toLowerCase())) {
          return true;
        }
        if (p.sku.toLowerCase().includes(searchString.toLowerCase())) {
          return true;
        }
        return false;
      });
    }
    console.log(products);
    return products;
  }, [searchString, list]);

  const [filteredProducts, setFilteredProducts] = useState(filterProducts());

  useEffect(() => {
    setFilteredProducts(filterProducts());
  }, [filterProducts]);

  const handleValueChange = useCallback(
    (value: number, storeProduct: IStoreProduct) => {
      const isProductExists = cartProducts.some(
        (p) => p.productId === storeProduct.productId
      );
      let updatedCartProducts = [];
      if (isProductExists) {
        updatedCartProducts = cartProducts.map((product) => {
          if (product.productId === storeProduct.productId) {
            return {
              ...product,
              quantity: value,
            };
          }
          return product;
        });
      } else {
        updatedCartProducts = [
          ...cartProducts,
          { ...storeProduct, quantity: value },
        ];
      }

      const updatedProducts = list.map((product) => {
        if (product.productId === storeProduct.productId) {
          return {
            ...product,
            quantity: value,
          };
        }
        return product;
      });

      updatedCartProducts = updatedCartProducts.filter((p) => p.quantity);

      dispatch(actions.updateProducts(updatedProducts));
      dispatch(actions.updateCartProducts(updatedCartProducts));
    },
    [dispatch, cartProducts, list]
  );

  const columns = useMemo(
    () => [
      {
        title: t("name"),
        key: "name",
        sorter: true,
        render: (product: IStoreProduct) => {
          return (
            <ProductPhotoCell name={product.name} imageId={product.imageId} />
          );
        },
      },
      {
        title: t("sku"),
        key: "sku",
        dataIndex: "sku",
      },
      {
        title: t("totalQuantity"),
        key: "totalQuantity",
        dataIndex: "totalQuantity",
      },
      {
        title: t("availableQuantity"),
        key: "availableQuantity",
        dataIndex: "availableQuantity",
      },
      {
        title: t("assignedQuantity"),
        key: "assignedQuantity",
        dataIndex: "assignedQuantity",
      },
      {
        title: t("reservedQuantity"),
        key: "reservedQuantity",
        dataIndex: "reservedQuantity",
      },
      {
        title: t("quantity"),
        key: "quantity",
        render: (product: IStoreProduct) => {
          return (
            <LMInputNumberIncremental
              name="list"
              max={product.availableQuantity}
              value={product.quantity}
              onValueChange={(value: number) =>
                handleValueChange(value, product)
              }
            />
          );
        },
      },
    ],
    [t, handleValueChange]
  ) as ColumnType<IStoreProduct>[];

  return (
    <LMTable
      loading={status === ApiStatuses.loading}
      columns={columns}
      dataSource={filteredProducts}
      total={{ title: t("total"), amount: list.length }}
      onSortChange={handleSortNameChange}
      rowKey="productId"
    />
  );
};
