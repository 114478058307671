import { Tooltip } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  IconBoard,
  IconClearFilters,
  IconInfoBlue,
  IconSearchSmall,
  IconTable,
} from "../../../assets";
import { LMNewInput } from "../../../components";
import { LMIconButton } from "../../../components/LMIconButton";
import { LMMultiSelect } from "../../../components/LMMultiSelect";
import { LMNewRadio } from "../../indexing/LMNewRadio";
import { LMRangePicker } from "../../../components/LMRangePicker";
import { EOrdersViewType, IOrdersListFilters } from "../types";
import { RangeValue } from "../../../components/LMRangePicker";
import { getOrderStatusesForFilter } from "../utils";
import { Button2 } from "../../../components/Typography";
import styles from "./OrderFilters.module.css";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { actions } from "../slice";

interface Props {
  filters: IOrdersListFilters;
  onSearchLastChanged: (value: boolean) => void;
}

export const OrderFilters: FC<Props> = ({ filters, onSearchLastChanged }) => {
  const { t } = useTranslation("orders");
  const { t: tOrder } = useTranslation("order");
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();

  const handleStatusesChange = (value: string[]) => {
    onSearchLastChanged(false);
    searchParams.set("statuses", value.join(","));
    setSearchParams(searchParams);
  };

  const handleSearchChange = (evt: SyntheticEvent) => {
    onSearchLastChanged(true);
    const target = evt.target as HTMLInputElement;
    searchParams.set("search", target.value);
    setSearchParams(searchParams);
  };

  const handleIdChange = (evt: SyntheticEvent) => {
    onSearchLastChanged(false);
    const target = evt.target as HTMLInputElement;
    searchParams.set("id", target.value);
    setSearchParams(searchParams);
  };

  const handleDateChange = (values: RangeValue) => {
    onSearchLastChanged(false);
    if (!values) return;
    const start = values[0] as Date;
    const end = values[1] as Date;
    searchParams.set("start", start.toString());
    searchParams.set("end", end.toString());
    setSearchParams(searchParams);
  };

  const handleClearFilters = () => {
    onSearchLastChanged(false);
    setSearchParams({ viewType: filters.viewType || EOrdersViewType.table });
    dispatch(actions.clearDetails());
  };

  const handleSetViewType = (value: SegmentedValue) => {
    onSearchLastChanged(false);
    searchParams.set("viewType", value as string);
    setSearchParams(searchParams);
  };

  const disabledDate = (current: Date) => {
    return current > new Date();
  };

  return (
    <div className={styles.container}>
      {filters.viewType === EOrdersViewType.table && (
        <Tooltip
          trigger={["focus"]}
          color="white"
          title={
            <Button2 className={styles.hint}>
              <IconInfoBlue />
              {t("searchHint")}
            </Button2>
          }
          placement="bottom"
          overlayClassName={styles["hint-overlay"]}
        >
          <LMNewInput
            placeholder={t("searchPlaceholder")}
            onChange={handleSearchChange}
            icon={<IconSearchSmall />}
            value={searchParams.get("search") || ""}
          />
        </Tooltip>
      )}
      <LMNewInput
        placeholder={t("idPlaceholder")}
        onChange={handleIdChange}
        icon={<IconSearchSmall />}
        value={filters.idFilter}
      />
      {filters.viewType === EOrdersViewType.table && (
        <>
          <LMRangePicker
            value={[
              filters.dateFilter.start || null,
              filters.dateFilter.end || null,
            ]}
            size="large"
            onChange={handleDateChange}
            disabledDate={disabledDate}
          />
          <div className={styles.status}>
            <LMMultiSelect
              value={filters.statusFilter}
              onChange={handleStatusesChange}
              placeholder={t("statusPlaceholder")}
              items={getOrderStatusesForFilter().map((status) => ({
                name: tOrder(status),
                value: status,
              }))}
            />
          </div>
        </>
      )}
      <Tooltip placement="top" title={t("resetFilters")}>
        <LMIconButton color="white" onClick={handleClearFilters}>
          <IconClearFilters />
        </LMIconButton>
      </Tooltip>
      <div className={styles["switch-container"]}>
        <LMNewRadio
          onChange={handleSetViewType}
          size="large"
          options={[
            {
              label: (
                <div className={styles["switch-item-container"]}>
                  <IconTable />
                  {t("table")}
                </div>
              ),
              value: EOrdersViewType.table,
            },
            {
              label: (
                <div className={styles["switch-item-container"]}>
                  <IconBoard />
                  {t("board")}
                </div>
              ),
              value: EOrdersViewType.board,
            },
          ]}
          value={filters.viewType || EOrdersViewType.table}
        />
      </div>
    </div>
  );
};
