import { AxiosPromise } from "axios";
import { axios } from '../axios';
import { IStation, IStationProduct, ISystemProduct } from "./types";
import { IOrder } from "../orders";
import { IApiList } from "../../app/types";
//import { stations } from "./mock";

const getIdFilter = (ids: string[]) => {
  let query = "";
  ids.forEach((id) => {
    query = `${query}&ordersIds=${id}`;
  });
  return query;
};

export const getStations = (systemId: number): AxiosPromise<IStation[]> => {
  //return Promise.resolve({ data: stations }) as unknown as AxiosPromise;
  return axios.get(`/stations/systems/${systemId}`);
}

export const getTenantStock = (
  tenantId: number,
): AxiosPromise<ISystemProduct[]> => {
  return axios.get(`/tenants/${tenantId}/available-stock`);
}

export const getStationProducts = (systemId: number, palletId: string): AxiosPromise<IStationProduct[]> => {
  return axios.get(`/stations/systems/${systemId}/pallets/${palletId}/stock`);
}

export const exportToCSV = (tenantId: number): AxiosPromise<string> => {
  return axios.get(`/tenants/${tenantId}/available-stock/report`);
}

export const getStationOrders = (tenantId: number, orderIds: string[]): AxiosPromise<IApiList<IOrder>> => {
  return axios.get(
    `/orders?tenantId=${tenantId}&page=1&pageSize=100${getIdFilter(orderIds)}`
  );
}