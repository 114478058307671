import { Tooltip } from "antd";
import { FC, SyntheticEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  IconClearFilters,
  IconExport,
  IconPlus,
  IconSearchSmall,
} from "../../../assets";
import { LMDropdown, LMNewInput } from "../../../components";
import { LMIconButton } from "../../../components/LMIconButton";
import { LMMultiSelect } from "../../../components/LMMultiSelect";
import {
  EProductStatus,
  IProductCategory,
  IProductsListFilters,
} from "../types";
import { LMNewButton } from "../../../components/LMNewButton";
import { AbilityContext } from "../../casl";
import styles from "./ProductsPortfolioFilters.module.css";

interface Props {
  filters: IProductsListFilters;
  categories: IProductCategory[];
  onShowCreateProductModal: () => void;
  onExport: () => void;
}

export const ProductsPortfolioFilters: FC<Props> = ({
  filters,
  categories,
  onShowCreateProductModal,
  onExport,
}) => {
  const { t } = useTranslation("productsPortfolio");
  const [searchParams, setSearchParams] = useSearchParams();
  const ability = useContext(AbilityContext);

  const handleCategoriesChange = (value: string[]) => {
    searchParams.set("categories", value.join(","));
    setSearchParams(searchParams);
  };

  const handleStatusesChange = (value: string) => {
    searchParams.set("status", value);
    setSearchParams(searchParams);
  };

  const handleIdSearchChange = (evt: SyntheticEvent) => {
    const target = evt.target as HTMLInputElement;
    searchParams.set("searchString", target.value);
    setSearchParams(searchParams);
  };

  // const handleDisabledSystemsChange = () => {
  //   searchParams.set("disabledSystems", (!disabledSystemsFilter).toString());
  //   setSearchParams(searchParams);
  // };

  const handleClearFilters = () => {
    setSearchParams({});
  };

  return (
    <div className={styles.container}>
      <LMNewInput
        placeholder={t("searchPlaceholder")}
        onChange={handleIdSearchChange}
        icon={<IconSearchSmall />}
        value={filters.searchString}
      />

      <div className={styles.categories}>
        <LMMultiSelect
          value={filters.categoriesFilter}
          onChange={handleCategoriesChange}
          placeholder={t("categoriesPlaceholder")}
          items={categories.map((category) => ({
            name: category.name,
            value: category.id.toString(),
          }))}
        />
      </div>

      <div className={styles.statuses}>
        <LMDropdown
          value={filters.statusFilter}
          onChange={handleStatusesChange}
          placeholder={t("statusesPlaceholder")}
          items={Object.keys(EProductStatus).map((status) => ({
            name: t(status.toLowerCase()),
            value: status,
          }))}
        />
      </div>

      {/* <div className={styles["disabled-systems"]}>
        <LMCheck
          checked={disabledSystemsFilter}
          onChange={handleDisabledSystemsChange}
        />{" "}
        <Body2>{t("disabledSystemsFilter")}</Body2>{" "}
        <Button1 className={styles["amount-label"]}>12</Button1>
      </div> */}
      <Tooltip placement="top" title={t("resetFilters")}>
        <LMIconButton color="white" onClick={handleClearFilters}>
          <IconClearFilters />
        </LMIconButton>
      </Tooltip>
      <div className={styles.buttons}>
        <div className={styles["export-button-container"]}>
          <LMNewButton
            onClick={onExport}
            iconRight={<IconExport stroke="var(--color-gray-80)" />}
            color="default"
          >
            {t("export")}
          </LMNewButton>
        </div>
        {ability.can("managing", "products") && (
          <div className={styles["create-button-container"]}>
            <LMNewButton
              iconRight={<IconPlus />}
              onClick={onShowCreateProductModal}
            >
              {t("createProductButton")}
            </LMNewButton>
          </div>
        )}
      </div>
    </div>
  );
};
