import { FC, SyntheticEvent, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { actions } from "../slice";
import { useTranslation } from "react-i18next";
import { LMNewButton } from "../../../components/LMNewButton";
import styles from "./OrderTableSearch.module.css";
import { LMNewInput } from "../../../components";

interface Props {
  name: string;
}

export const OrderTableSearch: FC<Props> = ({ name }) => {
  const { t } = useTranslation("orders");
  const [search, setSearch] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const handleSetSearch = useCallback(
    (event: SyntheticEvent) => {
      const target = event.target as HTMLInputElement;
      setSearch(target.value);
    },
    [setSearch]
  );

  const handleSearch = useCallback(() => {
    dispatch(actions.setDetailsFilters({ [name]: search }));
  }, [name, dispatch, search]);

  const handleClear = useCallback(() => {
    setSearch("");
    dispatch(actions.setDetailsFilters({ [name]: "" }));
  }, [name, dispatch]);

  return (
    <div className={styles.container} onKeyDown={(e) => e.stopPropagation()}>
      <LMNewInput
        placeholder={`Search ${name}`}
        value={search}
        onChange={handleSetSearch}
      />
      <LMNewButton small color="primary" onClick={handleSearch}>
        {t("search")}
      </LMNewButton>
      <LMNewButton small color="default" onClick={handleClear}>
        {t("clear")}
      </LMNewButton>
    </div>
  );
};
