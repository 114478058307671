import { AxiosPromise } from 'axios';
import { IApiList } from '../../app/types';
import { axios } from '../axios';
import { ICreateProduct, IProductFormValues, IProduct, IProductCategory, IProductsListFilters } from './types';
import { getPagination, getSearchFilter, getSortFilter } from '../../app/utils';

const getCategoryFilter = (categories: string[]) => {
  if (categories.length === 0) {
    return "";
  }
  let query = '';
  categories.forEach(category => {
    query = `${query}&category=${category}`;
  })
  return query;
}

export const getProductsPortfolio = (
  filters: IProductsListFilters
): AxiosPromise<IApiList<IProduct>> => {
  const {
    page,
    pageSize,
    searchString: search,
    categoriesFilter: categories,
    nameFilterSort: nameSort,
    statusFilter: status,
  } = filters;
  return axios.get(`/products${getPagination(page, pageSize, true)}${getSearchFilter(search, "skuOrName")}${getCategoryFilter(categories)}${getSearchFilter(status, "status")}${getSortFilter(nameSort, "name")}`);
}

export const getProductCategories = (): AxiosPromise<IProductCategory[]> => {
  return axios.get(`/products/categories`);
}

export const createProduct = (product: ICreateProduct): AxiosPromise<IProduct> => {
  return axios.post('/products', product);
}

export const addProductSku = (productId: number, sku: { sku: string, barcodes: string[] }): AxiosPromise<IProduct> => {
  return axios.post(`/products/${productId}/skus`, sku);
}

export const addProductSkuBarcode = (skuId: string, barcode: number): AxiosPromise<IProduct> => {
  return axios.post(`/products/skus/${skuId}/barcodes`, { barcodes: [barcode] });
}

export const createCategory = (category: { name: string }): AxiosPromise<IProductCategory> => {
  return axios.post('/products/categories', category);
}

export const getProduct = (productId: number): AxiosPromise<IProduct> => {
  return axios.get(`/products/${productId}`);
}

export const updateProduct = (productId: number, product: Partial<IProductFormValues>): AxiosPromise<IProduct> => {
  return axios.patch(`/products/${productId}`, product);
}

export const approveProducts = (ids: number[]) => {
  return axios.post('/products/approve', ids);
}

export const revertProducts = (ids: number[]) => {
  return axios.post('/products/approve?approve=false', ids);
}

export const declineProducts = (ids: number[]) => {
  return axios.post('/products/decline', ids);
}


export const exportToCSV = (
  filters: IProductsListFilters
): AxiosPromise<string> => {
  const {
    searchString: search,
    categoriesFilter: categories,
    nameFilterSort: nameSort,
    statusFilter: status,
  } = filters;
  return axios.get(`/products/report/${getPagination(-1, -1, true)}${getSearchFilter(search, "skuOrName")}${getCategoryFilter(categories)}${getSearchFilter(status, "status")}${getSortFilter(nameSort, "name")}`);
}