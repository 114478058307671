import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IConfigSliceState } from './types';
import { ApiStatuses } from '../../app/types';
import { getConfig } from './api';

export const initialState: IConfigSliceState = {
  status: ApiStatuses.initial,
  config: {
    orderDetailsColumns: []
  }
};

export const fetchConfig = createAsyncThunk(
  'config/getConfig',
  async () => {
    const response = await getConfig();
    return response.data;
  }
);

const slice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchConfig
      .addCase(fetchConfig.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchConfig.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.config = action.payload;
      })
      .addCase(fetchConfig.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
  },
});

export const config = slice.reducer;
export const actions = slice.actions;