import { Empty, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { capitalizeFirst } from "../../../app/utils";
import { actions, fetchBlobs } from "../slice";
import { Body1, Body2, H3 } from "../../../components/Typography";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { DATE_TIME_FORMAT } from "../../../app/constants";
import { EMediaType } from "../types";
import classNames from "classnames/bind";
import styles from "./MediaPlayModal.module.css";

const cx = classNames.bind(styles);

export const MediaPlayModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("monitoring");
  const [searchParams, setSearchParams] = useSearchParams();
  const { blobs, list, selectedMedia } = useSelector((state: RootState) => {
    return state.monitoring;
  });
  const [selectedMediaItem, setSelectedMediaItem] = useState<string>("");

  const handleClose = () => {
    setSelectedMediaItem("");
    dispatch(actions.resetSelectedMedia());
    searchParams.delete("mediaId");
    setSearchParams(searchParams);
  };

  const getBlobs = useCallback(() => {
    if (!selectedMedia) return;
    dispatch(
      fetchBlobs({
        requestId: selectedMedia.requestId,
        mediaType: selectedMedia.mediaType,
      })
    );
  }, [selectedMedia, dispatch]);

  useEffect(() => getBlobs(), [getBlobs]);

  useEffect(() => {
    const mediaId = searchParams.get("mediaId");
    if (!mediaId) return;
    const media = list.find((m) => m.id === parseInt(mediaId, 0));
    if (media) {
      dispatch(actions.setSelectedMedia(media));
    }
  }, [searchParams, dispatch, list]);

  useEffect(() => {
    if (selectedMedia && blobs && !selectedMediaItem) {
      setSelectedMediaItem(Object.values(blobs)[0]);
    }
  }, [selectedMedia, selectedMediaItem, blobs]);

  return (
    <Modal
      width="100%"
      className={styles.modal}
      footer={null}
      open={!!selectedMedia}
      onCancel={handleClose}
      destroyOnClose
    >
      {selectedMedia && blobs && Object.values(blobs).length === 0 && (
        <div className={styles["empty-container"]}>
          <Empty
            description={
              selectedMedia.mediaType === EMediaType.videos
                ? t("noVideos")
                : t("noImages")
            }
          />
        </div>
      )}
      {selectedMedia && blobs && Object.values(blobs).length !== 0 && (
        <>
          <div className={styles.header}>
            <H3 className={styles.title}>{t("mediaPreview")}</H3>
            <Body2>
              {format(new Date(selectedMedia?.from), DATE_TIME_FORMAT)}
            </Body2>
          </div>
          <div className={styles.content}>
            <div className={styles.list}>
              {Object.keys(blobs).map((blobKey) => (
                <div
                  className={cx({
                    media: true,
                    selected: blobs[blobKey] === selectedMediaItem,
                  })}
                  key={blobKey}
                  onClick={() => setSelectedMediaItem(blobs[blobKey])}
                >
                  <div className={styles["media-container"]}>
                    {selectedMedia.mediaType === EMediaType.videos ? (
                      <video src={blobs[blobKey]}></video>
                    ) : (
                      <img src={blobs[blobKey]} alt={blobKey} />
                    )}
                    <Body1>{capitalizeFirst(blobKey)}</Body1>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles["selected-media"]}>
              {selectedMedia.mediaType === EMediaType.videos ? (
                <video
                  controls
                  autoPlay
                  src={
                    typeof (InstallTrigger as unknown) !== "undefined"
                      ? undefined
                      : selectedMediaItem
                  }
                >
                  <source src={selectedMediaItem} type="video/mp4" />
                  <source src={selectedMediaItem} type="video/mp4" />
                </video>
              ) : (
                <img src={selectedMediaItem} alt={selectedMedia.mediaType} />
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};
