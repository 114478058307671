import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ISystemProduct } from "../types";
import { ColumnType } from "antd/lib/table";
import { ApiStatuses } from "../../../app/types";
import { LMTable } from "../../../components/LMTable";
import { LMNewButton } from "../../../components/LMNewButton";
import { IconExport } from "../../../assets";

interface Props {
  products: ISystemProduct[];
  status: ApiStatuses;
  onExport: () => void;
}

export const SystemProductsList: FC<Props> = ({
  products,
  status,
  onExport,
}) => {
  const { t } = useTranslation("system");

  const columns = useMemo(
    () => [
      {
        title: t("name"),
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        },
      },
      {
        title: t("sku"),
        key: "sku",
        dataIndex: "sku",
      },
      {
        title: t("totalQuantity"),
        key: "totalQuantity",
        dataIndex: "totalQuantity",
      },
      {
        title: t("availableQuantity"),
        key: "availableQuantity",
        dataIndex: "availableQuantity",
      },
      {
        title: t("assignedQuantity"),
        key: "assignedQuantity",
        dataIndex: "assignedQuantity",
      },
      {
        title: t("reservedQuantity"),
        key: "reservedQuantity",
        dataIndex: "reservedQuantity",
      },
    ],
    [t]
  ) as ColumnType<ISystemProduct>[];

  return (
    <LMTable
      action={
        <LMNewButton
          onClick={onExport}
          iconRight={<IconExport />}
          type="primary"
          small
        >
          {t("export")}
        </LMNewButton>
      }
      loading={status === ApiStatuses.loading}
      columns={columns}
      dataSource={products}
      rowKey="productId"
      total={{ title: t("total"), amount: products.length }}
    />
  );
};
