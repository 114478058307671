import { FC, useCallback, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import {
  fetchOrders,
  fetchBoard,
  OrderFilters,
  ordersActions,
  OrdersList,
  EOrdersViewType,
  OrdersBoard,
} from "../../modules/orders";
import { exportToCSV } from "../../modules/orders/api";
import styles from "./Orders.module.css";
import { format } from "date-fns";

interface Props {
  tenantId: number;
}

export const Orders: FC<Props> = ({ tenantId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const [isSearchLastChange, setIsSearchLastChange] = useState(false);

  const { status, filters, list, board, selectedOrdersToProcess } = useSelector(
    (state: RootState) => {
      return state.orders;
    }
  );

  useEffect(() => {
    const start = searchParams.get("start");
    const end = searchParams.get("end");
    const search = searchParams.get("search") || "";
    const statuses = searchParams.get("statuses") || "";
    const id = searchParams.get("id") || "";
    if (search && search.length < 2 && isSearchLastChange) return;
    dispatch(
      ordersActions.setFilters({
        search,
        id: id,
        statuses,
        viewType: searchParams.get("viewType") || EOrdersViewType.table,
        dates: {
          start: start ? new Date(start) : null,
          end: end ? new Date(end) : null,
        },
      })
    );
  }, [searchParams, dispatch, isSearchLastChange]);

  const getOrders = useCallback(() => {
    dispatch(
      fetchOrders({
        tenantId,
        filters,
      })
    );
  }, [tenantId, dispatch, filters]);

  const getBoard = useCallback(() => {
    dispatch(
      fetchBoard({
        tenantId,
        idFilter: filters.idFilter,
      })
    );
  }, [tenantId, dispatch, filters]);

  useEffect(() => {
    if (filters.viewType === null) return;
    if (filters.viewType === EOrdersViewType.board) {
      getBoard();
      return;
    }
    getOrders();
  }, [getOrders, getBoard, filters]);

  const handleExportOrders = useCallback(async () => {
    const csv = await exportToCSV(tenantId, filters);
    const blob = new Blob([csv.data], { type: "text/csv;charset=utf-8" });
    const name = `orders_${format(new Date(), "dd-mm-yyyy_HH:MM")}`;
    saveAs(blob, name);
  }, [tenantId, filters]);

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <OrderFilters
          filters={filters}
          onSearchLastChanged={setIsSearchLastChange}
        />
      </div>
      <div className={styles.list}>
        {filters.viewType === EOrdersViewType.table ? (
          <OrdersList
            onRefresh={getOrders}
            onExport={handleExportOrders}
            orders={list}
            selectedOrdersToProcess={selectedOrdersToProcess}
            status={status}
            filters={filters}
          />
        ) : (
          <OrdersBoard orders={board} />
        )}
      </div>
    </div>
  );
};
