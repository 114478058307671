import { AxiosPromise } from 'axios';
import { axios } from '../axios';
import { ISystemDto, IUpdateSystemDto } from './types';

export const getSystems = (): AxiosPromise<ISystemDto[]> => {
  // return Promise.resolve({ data: [{
  //   id: 6,
  //   activeTasks: [],
  //   createdOn: "",
  //   machineSpeed: EMachineSpeedOptions.Fast,
  //   name: "test",
  //   offSite: false,
  //   serialNumber: "1",
  //   tenantId: 4,
  //   updatedOn: "t4st"
  // }] }) as unknown as AxiosPromise<ISystemDto[]>;
  return axios.get('/systems');
};

export const getSystem = (systemId: number): AxiosPromise<ISystemDto> => {
  return axios.get(`/systems/${systemId}`);
};

export const updateSystem = (systemId: number, system: IUpdateSystemDto): AxiosPromise<ISystemDto> => {
  return axios.patch(`/systems/${systemId}`, system);
};