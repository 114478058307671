import { EMetadataTypes, IMetadataEntry } from "../../metadata/types";
import { IUpdateProduct, IProductFormValues } from "../types";

export const mapProductToUpdate = (values: IProductFormValues): IUpdateProduct => {
  return {
    name: values.name,
    categoryId: values.categoryId,
    temperature: values.temperature,
    external: values.external,
    metadata: values.metadata,
    base64Image: values.base64Image ? values.base64Image.split(',')[1] : ""
  }
}

export const mapMetadataToForm = (metadata: Record<string, string>, metadataEntries: IMetadataEntry[]): Record<string, string | boolean> => {
  const updatedMetadata: Record<string, string | boolean> = { ...metadata };
  Object.keys(updatedMetadata).forEach((key) => {
    const entry = metadataEntries.find((entry) => entry.name.toLowerCase() === key.toLowerCase()) as IMetadataEntry;
    if (entry.type === EMetadataTypes.Bool && updatedMetadata[key] === "true") {  
      updatedMetadata[key] = true;
      return;
    }
    if (entry.type === EMetadataTypes.Bool && updatedMetadata[key] === "false") {
      updatedMetadata[key] = false;
    }
  });
  return updatedMetadata;
}
